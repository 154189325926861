import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
	projectId: 'w7jpwz5k',
	dataset: 'production',
	apiVersion: '2022-03-05',
	useCdn: true,
	token: process.env.SANITY_SVETRO_TOKEN,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
